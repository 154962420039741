import { Link } from "react-router-dom";
import ".././App.css";

const Home = () => {
  return (
    <section className="about">
      <br></br>
      <h2>My DepEd Travel Level</h2>
      <div id="ezoic-pub-ad-placeholder-106"> </div>
      DepEd's well-travelled app is here to help you visualize your travels
      within the Philippines because of DepEd.
      <br></br>
      <br></br>
      This project was inspired by the{" "}
      <div
        className="link"
        onClick={() => window.open("https://zhung.com.tw/japanex/")}
      >
        Japan↗
      </div>{" "}
      version and{" "}
      <div
        className="link"
        onClick={() => window.open("https://my-philippines-travel-level.com/")}
      >
        my-philippines-travel-level.com↗.
      </div>
      .<h2>How To Use</h2>
      The map is divided into the 83* Philippine provinces. All you have to do
      is to click on a province and choose the appropriate level of travel.
      <br></br>
      <br></br>
      Here's what each level represents:
      <br></br>
      <br></br>
      <li className="bullet">
        <b>Palarong Pambansa</b> - you attended the Palarong Pambansa.
      </li>
      <li className="bullet">
        <b>Trainings/Conferences</b> - you attended
        trainings/seminars/conferences
      </li>
      <li className="bullet">
        <b>DepEd Competition</b> - you attended deped competition.
      </li>
      <li className="bullet">
        <b>Benchmark/Drop by</b> - you visited for benchmarking or drop by
      </li>
      <li className="bullet">
        <b>Passed by</b> - you passed by that area but did not set foot.
      </li>
      <li className="bullet">
        <b>Never been there</b>. - never been there.
      </li>
      <br></br>
      <div id="ezoic-pub-ad-placeholder-109"> </div>
      Your total score will be updated accordingly. You can then save the image
      and share on your social media.
      <br></br>
      Click{" "}
      <Link className="link" to={"/map"}>
        here
      </Link>{" "}
      to view the map. Enjoy!
      <br></br>
      <br></br>
      <section className="asterisk">
        *Technically, there are only 82 provinces. Metro Manila is an
        administrative region.
      </section>
      <h2>Inspired by</h2>
      The work of sir Denz.
      <br></br>
      <br></br>
      If you want to give a cup of coffee, here's my GCASH number 09237310322.
      Thank you!
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="flag-counter">
        <a href="https://info.flagcounter.com/oAlc">
          <img
            src="https://s11.flagcounter.com/count2/oAlc/bg_FFFFFF/txt_000000/border_CCCCCC/columns_2/maxflags_10/viewers_0/labels_0/pageviews_0/flags_0/percent_0/"
            alt="Flag Counter"
            border="0"
          />
        </a>
      </div>
    </section>
  );
};

export default Home;
